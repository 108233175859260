// Generated by ts-to-zod
import { z } from 'zod';

export const problemJsonSchema = z.object({
  type: z.string().optional().default('about:blank'),
  title: z.string().optional(),
  status: z.number().optional(),
  detail: z.string().optional(),
  instance: z.string().optional()
});

export const paymentNoticeSchema = z.object({
  noticeNumber: z.string().min(18).max(18),
  fiscalCode: z.string().min(11).max(11),
  amount: z.number(),
  companyName: z.string().max(140),
  description: z.string().max(140)
});

export const cartRequestSchema = z.object({
  emailNotice: z.string().email().optional(),
  idCart: z.string().optional(),
  paymentNotices: z.array(paymentNoticeSchema),
  returnUrls: z.object({
    returnOkUrl: z.string(),
    returnCancelUrl: z.string(),
    returnErrorUrl: z.string()
  }),
  allCCP: z.boolean().optional()
});
